// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-select {
  color: var(--kt-form-select-color);
  background-color: var(--kt-form-select-bg);
  background-image: var(--kt-form-select-indicator);
  border: $form-select-border-width solid var(--kt-form-select-border-color);
  @include box-shadow(var(--kt-form-select-box-shadow));
  appearance: none;

  &:focus {
    border-color: var(--kt-form-select-focus-border-color);
    @if $enable-shadows {
      @include box-shadow(
        var(--kt-form-select-box-shadow),
        var(--kt-form-select-focus-box-shadow)
      );
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: var(--kt-form-select-focus-box-shadow);
    }
  }

  &:disabled {
    color: var(--kt-form-select-disabled-color);
    background-color: var(--kt-form-select-disabled-bg);
    border-color: var(--kt-form-select-disabled-border-color);
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    text-shadow: 0 0 0 var(--kt-form-select-color);
  }
}
